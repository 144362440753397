export const processImageWithDeepAiApi = async (image: File) => {
  const formData = new FormData();
  formData.append("image", image);
  const response = await fetch("https://api.deepai.org/api/deepdream", {
    headers: {
      "api-key": "462d7578-1189-4662-9de5-4688d772af84",
    },
    method: "POST",
    body: formData,
  });
  const responseData = await response.json();
  if (!response.ok) {
    throw responseData.err;
  }

  if (responseData.err) throw new Error(responseData.err);
  return responseData.output_url;
};
