import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import styles from "./NftEditor.module.scss";
import SvgDesigner, { SvgDesignerItem } from "../svgDesigner/SvgDesigner";
import { processImageWithDeepAiApi } from "../../utils/deepAiApi";
import { fillImg, FillType } from "../../utils/canvas";
import Select, { GroupBase } from "react-select";

const COLORS = {
  WHITE: "#FFFFFF",
  GRAY: "#D4D4D4",
};

const NftEditor: FC = () => {
  const BLENDING_MODES = [
    "normal",
    "multiply",
    "screen",
    "darken",
    "lighten",
    "overlay",
    "color-dodge",
    "color-burn",
    "hard-light",
    "soft-light",
    "difference",
    "exclusion",
    "hue",
    "saturation",
    "color",
    "luminosity",
    "plus-darker",
    "plus-lighter",
  ];
  const blendingCanvas = useRef<HTMLCanvasElement>(null);
  const [blendingImages, setBlendingImages] = useState<HTMLElement[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const outputCanvasRef = useRef<HTMLCanvasElement>(null);
  const [exportingVideo, setExportingVideo] = useState(false);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [userText, setUserText] = useState("read write own");
  const selectBlendModeOptions = BLENDING_MODES.map((mode, idx) => ({
    value: mode,
    label: mode,
  })) as unknown as GroupBase<string>[];
  const [blendMode, setBlendMode] = useState(
    selectBlendModeOptions.find((o) => o.label === "multiply") || "multiply"
  );

  const init = () => {
    const imageSources = ["", "bg-video.mp4"];
    Promise.all(
      imageSources.map(
        async (src) =>
          new Promise((resolve, reject) => {
            if (src === "") {
              const i = new Image();
              i.src = src;
              i.width = 500;
              i.height = 500;
              resolve(i);
            }
            if (src.split(".").pop() === "mp4") {
              const i = document.createElement("video");
              i.src = src;
              i.width = 500;
              i.height = 500;
              i.muted = true;
              i.loop = true;
              i.onloadeddata = () => {
                i.play();
                resolve(i);
              };
              i.onerror = () => {
                reject();
              };
            } else {
              const i = new Image();
              i.src = src;
              i.width = 500;
              i.height = 500;
              i.crossOrigin = "anonymous";
              i.onload = () => {
                resolve(i);
              };
              i.onerror = () => {
                reject();
              };
            }
          })
      )
    ).then((images) => {
      setBlendingImages(images as HTMLImageElement[]);
      setLoading(false);
    });
  };

  const items: SvgDesignerItem[] = useMemo(
    () => [
      {
        type: "canvas",
        size: "fullscreen",
        value: blendingCanvas.current,
      },
      {
        type: "text",
        value: "proof of attendance",
        position: {
          top: {
            relative: true,
            value: 0.035,
          },
          left: {
            relative: true,
            value: 0.568,
          },
          width: {
            relative: true,
            value: 0.2,
          },
        },
        args: {
          fontSize: 15,
          fontFamily: "GroteskMono",
          fill: COLORS.WHITE,
          width: 200,
        },
      },
      {
        type: "text",
        value: "_cannes lions 2022",
        position: {
          top: {
            relative: true,
            value: 0.035,
          },
          left: 26,
        },
        args: {
          fontSize: 15,
          fontFamily: "GroteskMono",
          fill: COLORS.WHITE,
        },
      },
      {
        type: "text",
        value: userText,
        textTransform: "lowercase",
        position: {
          top: {
            relative: true,
            value: 0.25,
          },
          left: 26,
        },
        args: {
          fontSize: 50,
          fontFamily: "Tactic",
          fill: COLORS.WHITE,
          width: 450,
        },
      },
      {
        type: "text",
        value: "introduction to web3, nft and metaverse",
        position: {
          top: {
            relative: true,
            value: 0.375,
          },
          left: 26,
        },
        args: {
          fontSize: 15,
          fontFamily: "GroteskMono",
          fill: COLORS.GRAY,
          width: 450,
        },
      },
      {
        type: "image",
        value: "hyper-logo.svg",
        position: {
          top: {
            relative: true,
            value: 0.863,
          },
          left: {
            relative: true,
            value: 0.847,
          },
        },
        args: {
          scaleX: 0.5,
          scaleY: 0.5,
        },
      },
    ],
    [blendingCanvas.current, userText]
  );

  useEffect(() => {
    if (blendingImages.length) {
      console.log("skipping init");
      return;
    }
    if (!blendingCanvas.current) return;
    const c = blendingCanvas.current;
    const ctx = c.getContext("2d");
    if (!ctx) return;
    console.log("starting canvas");
    ctx.globalCompositeOperation = (blendMode as GroupBase<string>)
      .label as GlobalCompositeOperation;
    init();
  }, []);

  useEffect(() => {
    if (!blendingCanvas.current) return;
    const ctx = blendingCanvas.current.getContext("2d");
    if (!ctx) return;
    ctx.globalCompositeOperation = (blendMode as GroupBase<string>)
      .label as GlobalCompositeOperation;
  }, [blendMode]);

  const draw = useCallback(
    (ctx: CanvasRenderingContext2D, frameCount: number) => {
      ctx.clearRect(0, 0, 500, 500);
      blendingImages.forEach((img) => {
        const tagName = img.tagName.toLowerCase();
        if (tagName === "img" || tagName === "video")
          fillImg(ctx, img as HTMLImageElement, "cover");
      });
    },
    [blendingImages]
  );

  useEffect(() => {
    const canvas = blendingCanvas.current;
    if (!canvas) return;
    const context = canvas.getContext("2d");
    if (!context) return;
    let frameCount = 0;
    let animationFrameId: number;

    //Our draw came here
    const render = () => {
      frameCount++;
      draw(context, frameCount);
      animationFrameId = window.requestAnimationFrame(render);
    };
    render();

    return () => {
      window.cancelAnimationFrame(animationFrameId);
    };
  }, [draw]);

  const handleBackgroundChange = async (files: FileList | null) => {
    if (!files || files.length === 0) return;
    setUploadingImage(true);
    try {
      const apiResult = await processImageWithDeepAiApi(files[0]);
      const image = (await prepareImage(apiResult)) as HTMLImageElement;
      const newImages = [image, ...blendingImages.slice(1)];
      setBlendingImages(newImages);
    } catch (e: any) {
      setError(e);
      setUploadingImage(false);
      return;
    }
    setUploadingImage(false);
    setError("");
  };

  const prepareImage = (src: string, fill: FillType = "cover") =>
    new Promise((resolve, reject) => {
      const i = new Image();
      i.src = src;
      i.style.width = "100%";
      i.style.height = "100%";
      i.style.objectFit = fill;
      i.crossOrigin = "anonymous";
      i.onload = () => {
        resolve(i);
      };
      i.onerror = () => {
        reject();
      };
    });

  const downloadVideo = (chunks: BlobPart[]) => {
    const blob = new Blob(chunks, { type: "video/webm" });
    const videoURL = URL.createObjectURL(blob);
    const tag = document.createElement("a");
    tag.href = videoURL;
    tag.download = "nft.webm";
    document.body.appendChild(tag);
    tag.click();
    document.body.removeChild(tag);
  };

  const exportVideo = () => {
    const c = outputCanvasRef.current;
    const bgVideo = blendingImages.filter(
      (el) => el.tagName.toLowerCase() === "video"
    )[0] as HTMLVideoElement;
    setExportingVideo(true);
    if (!c) return;
    const canvasStream = c.captureStream(30);
    const mediaRecorder = new MediaRecorder(canvasStream, {
      mimeType: "video/webm",
    });
    let chunks: BlobPart[] = [];

    mediaRecorder.onstop = function () {
      downloadVideo(chunks);
      chunks = [];
    };

    mediaRecorder.ondataavailable = function (e) {
      chunks.push(e.data);
    };

    bgVideo.pause();
    bgVideo.currentTime = 0;
    bgVideo.loop = false;

    bgVideo.addEventListener(
      "play",
      () => {
        mediaRecorder.start();
      },
      { once: true }
    );

    bgVideo.addEventListener(
      "ended",
      () => {
        bgVideo.loop = true;
        bgVideo.currentTime = 0;
        bgVideo.play();
        mediaRecorder.stop();
        setExportingVideo(false);
      },
      { once: true }
    );
    bgVideo.play();
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.previewWrapper}>
        <div className={styles.previewBox}>
          <SvgDesigner
            items={items}
            onChange={() => {}}
            canvasRef={outputCanvasRef}
          />
        </div>
      </div>
      <div className={styles.detailsWrapper}>
        {loading ? (
          <p className={styles.loading}>Loading_</p>
        ) : (
          <>
            <div className={styles.inputGroup}>
              <input
                id="background-image"
                accept="image/png, image/jpg, image/jpeg"
                type="file"
                className={styles.fileInput}
                onChange={(e) => handleBackgroundChange(e.currentTarget.files)}
                disabled={uploadingImage}
              />
              <label htmlFor="background-image">
                {uploadingImage ? "Processing" : "Upload image"}
              </label>
            </div>
            <div className={styles.inputGroup}>
              {/* <label htmlFor="blending-select">Select blending mode:</label> */}
              <Select
                id="blending-select"
                value={blendMode}
                onChange={(v) => setBlendMode(v as string)}
                options={selectBlendModeOptions}
                className={styles.select}
                classNamePrefix={styles.select}
                components={{
                  IndicatorSeparator: null,
                  DropdownIndicator: null,
                }}
                isSearchable={false}
              />
            </div>
            {/* <div className={styles.inputGroup}>
              <label htmlFor="your-text">Your text:</label>
              <input
                type="text"
                id="your-text"
                value={userText}
                maxLength={24}
                onChange={(e) => setUserText(e.target.value)}
              />
            </div> */}
            <button onClick={exportVideo} disabled={exportingVideo}>
              {exportingVideo ? "Processing" : "Export"}
            </button>
            {error && (
              <p>
                <b>Error: </b>
                {error}
              </p>
            )}
          </>
        )}
      </div>
      <canvas
        style={{ display: "none" }}
        width="500"
        height="500"
        ref={blendingCanvas}
      ></canvas>
    </div>
  );
};

export default NftEditor;
