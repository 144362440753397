export type FillType = "contain" | "cover";

export const fillImg = (
  ctx: CanvasRenderingContext2D,
  img: HTMLImageElement,
  type: FillType
) => {
  const imgRatio = img.height / img.width;
  const { width: cw, height: ch } = ctx.canvas;
  const canvasRatio = ch / cw;
  if (
    (imgRatio < canvasRatio && type === "contain") ||
    (imgRatio > canvasRatio && type === "cover")
  ) {
    const h = ch * imgRatio;
    ctx.drawImage(img, 0, (ch - h) / 2, cw, h);
    return;
  }
  if (
    (imgRatio > canvasRatio && type === "contain") ||
    (imgRatio < canvasRatio && type === "cover")
  ) {
    const w = (ctx.canvas.height * canvasRatio) / imgRatio;
    ctx.drawImage(img, (cw - w) / 2, 0, w, ch);
    return;
  }
  ctx.drawImage(img, 0, 0, cw, ch);
};
