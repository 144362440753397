import { fabric } from "fabric";
import { FC, useCallback, useEffect, useRef } from "react";

export interface SvgDesignerItem {
  type: "image" | "video" | "text" | "canvas";
  value: (() => Promise<string>) | string | HTMLCanvasElement | null;
  justify?: "center" | "default";
  size?: "fullscreen";
  position?: {
    top?: number | SvgDesignerPositionProps;
    left?: number | SvgDesignerPositionProps;
    width?: number | SvgDesignerPositionProps;
    height?: number | SvgDesignerPositionProps;
  };
  textTransform?: "lowercase" | "uppercase";
  args?: fabric.IImageOptions | fabric.ITextOptions;
  maxDimensions?: {
    height?: number;
    width?: number;
  };
  filters?: [
    {
      type: string;
      config: any;
    }
  ];
}

interface SvgDesignerPositionProps {
  relative?: boolean;
  value: number;
}

interface IProps {
  items: SvgDesignerItem[];
  canvasRef: React.RefObject<HTMLCanvasElement>;
  onChange: (b64Data: string) => void;
  width?: number;
  height?: number;
}

const SvgDesigner: FC<IProps> = ({
  items,
  onChange,
  width = 500,
  height = 500,
  canvasRef,
}) => {
  const fabricCanvasRef = useRef<fabric.StaticCanvas | null>(null);

  const getFabricItem = useCallback(
    (configItem: any) =>
      new Promise<fabric.Object>(async (resolve, reject) => {
        if (!configItem.value) resolve(new fabric.Object());
        switch (configItem.type) {
          case "image":
            const src =
              typeof configItem.value === "function"
                ? await configItem.value()
                : configItem.value;
            const img = new Image();
            img.src = src;
            img.crossOrigin = "anonymous";
            img.onload = () => {
              resolve(new fabric.Image(img));
            };
            break;
          case "video":
            const vSrc =
              typeof configItem.value === "function"
                ? await configItem.value()
                : configItem.value;
            const video = document.createElement("video");
            video.src = vSrc;
            video.loop = true;
            video.muted = true;
            video.crossOrigin = "anonymous";
            video.onloadeddata = () => {
              video.width = video.videoWidth;
              video.height = video.videoHeight;
              video.play();
              resolve(new fabric.Image(video));
            };
            break;
          case "text":
            resolve(
              new fabric.Text(
                transformText(configItem.value, configItem.textTransform)
              )
            );
            break;
          case "canvas":
            resolve(new fabric.Image(configItem.value));
            break;
          default:
            reject("Unknown item type");
        }
      }),
    []
  );

  const transformText = (text: string, transform: string) => {
    switch (transform) {
      case "uppercase":
        return text.toUpperCase();
      case "lowercase":
        return text.toLowerCase();
      default:
        return text;
    }
  };

  const setItemPosition = useCallback(
    (
      item: fabric.Object,
      position: SvgDesignerItem["position"],
      cWidth: number,
      cHeight: number
    ) => {
      if (position) {
        Object.entries(position).forEach(([key, value]) => {
          if (typeof value === "number") {
            if (item.type === "image") {
              if (isPropRelatedToHeight(key)) {
                item.set({
                  originY: "top",
                  scaleY: value / item.getScaledHeight(),
                });
              } else {
                item.set({
                  originX: "left",
                  scaleX: value / item.getScaledWidth(),
                });
              }
            } else {
              item.set(key as any, value);
            }
          } else {
            const relatedToHeight =
              key === "top" || key === "bottom" || key === "height";
            const relativeValue = relatedToHeight ? cHeight : cWidth;
            item.set(
              key as keyof fabric.Object,
              (value as SvgDesignerPositionProps).value * relativeValue
            );
          }
        });
      }
    },
    []
  );

  const setItemMaxDimensions = useCallback(
    (item: fabric.Object, maxDimensions: SvgDesignerItem["maxDimensions"]) => {
      if (maxDimensions) {
        const hScale = Number(maxDimensions.width) / Number(item.width) || 1;
        const vScale = Number(maxDimensions.height) / Number(item.height) || 1;
        const minScale = Math.min(hScale, vScale);
        item.set({
          scaleX: minScale,
          scaleY: minScale,
        });
      }
    },
    []
  );

  const setItemDirectArgs = useCallback(
    (item: fabric.Object, args: SvgDesignerItem["args"]) => {
      if (args) {
        Object.entries(args).forEach(([key, value]) => {
          item.set(key as keyof fabric.Object, value);
        });
      }
    },
    []
  );

  const setItemCenter = useCallback((item: fabric.Object, cWidth: number) => {
    item.set("left", (cWidth - Number(item.getScaledWidth())) / 2);
  }, []);

  const setItemFullscreen = useCallback(
    (item: fabric.Object, cWidth: number, cHeight: number) => {
      item.set({
        scaleX: cWidth / Number(item.width),
        scaleY: cHeight / Number(item.height),
        originX: "left",
        originY: "top",
      });
    },
    []
  );

  const applyFilters = useCallback(
    async (item: fabric.Image, filters: SvgDesignerItem["filters"]) => {
      filters?.forEach(async (filter) => {
        let fabricFilter;
        switch (filter.type) {
          case "blend":
            const img = await processFabricItem(
              filter.config.image as SvgDesignerItem,
              500,
              500
            );
            fabricFilter = new fabric.Image.filters.BlendImage({
              image: img as fabric.Image,
              alpha: filter.config.alpha,
              mode: filter.config.mode,
            });
        }
        if (fabricFilter) {
          item.filters?.push(fabricFilter);
          if (fabricCanvasRef.current) {
            item.applyFilters();
          }
        }
      });
    },
    []
  );

  const isPropRelatedToHeight = (prop: string) => {
    return prop === "top" || prop === "bottom" || prop === "height";
  };

  const processFabricItem = useCallback(
    async (item: any, cWidth: number, cHeight: number) => {
      return new Promise<fabric.Object>(async (resolve, reject) => {
        const fabricItem = await getFabricItem(item);
        setItemPosition(fabricItem, item.position, cWidth, cHeight);
        setItemMaxDimensions(fabricItem, item.maxDimensions);
        setItemDirectArgs(fabricItem, item.args);
        if (item.justify === "center") {
          setItemCenter(fabricItem, cWidth);
        }
        if (item.size === "fullscreen") {
          setItemFullscreen(fabricItem, cWidth, cHeight);
        }
        if (item.filters && item.filters.length) {
          applyFilters(fabricItem as fabric.Image, item.filters);
        }
        resolve(fabricItem);
      });
    },
    [
      getFabricItem,
      setItemPosition,
      setItemMaxDimensions,
      setItemDirectArgs,
      setItemCenter,
      setItemFullscreen,
      applyFilters,
    ]
  );

  const renderPreview = useCallback(async () => {
    const c = fabricCanvasRef.current;
    if (!c) return;
    const cWidth = Number(canvasRef.current?.clientWidth);
    const cHeight = Number(canvasRef.current?.clientHeight);
    Promise.all(
      items.map(async (item) => {
        return await processFabricItem(item, cWidth, cHeight);
      })
    ).then((items) => {
      items.forEach((item, idx) => {
        c.add(item as any);
      });
    });
  }, [items, processFabricItem]);

  const handleChange = useCallback(() => {
    renderPreview().then(() => {
      try {
        const b64Data = fabricCanvasRef.current?.toDataURL();
        if (!b64Data) return;
        onChange(b64Data);
      } catch (e) {
        console.error(e);
      }
    });
  }, [onChange, renderPreview]);

  useEffect(() => {
    if (!canvasRef.current) return;
    if (fabricCanvasRef.current) {
      fabricCanvasRef.current.dispose();
    }
    fabricCanvasRef.current = new fabric.StaticCanvas(canvasRef.current, {
      width,
      height,
    });
    fabric.util.requestAnimFrame(function render() {
      fabricCanvasRef.current?.renderAll();
      fabric.util.requestAnimFrame(render);
    });
  }, [width, height]);

  useEffect(() => {
    handleChange();
  }, [handleChange]);

  return <canvas ref={canvasRef}></canvas>;
};

export default SvgDesigner;
