import './App.css';
import NftEditor from './components/nftEditor/NftEditor';

function App() {
  return (
    <div className="App">
      <img className="logo" src="hyper-logo-text.svg" alt="" />
      <NftEditor />
    </div>
  );
}

export default App;
